import { render, staticRenderFns } from "./DatiRegistraPreventivo.vue?vue&type=template&id=a851f924&scoped=true&"
import script from "./DatiRegistraPreventivo.vue?vue&type=script&lang=js&"
export * from "./DatiRegistraPreventivo.vue?vue&type=script&lang=js&"
import style0 from "./DatiRegistraPreventivo.vue?vue&type=style&index=0&id=a851f924&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a851f924",
  null
  
)


import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QDialog,QCard,QCardSection,QCardActions,QBtn});qInstall(component, 'directives', {ClosePopup});
/* hot reload */
if (module.hot) {
  var api = require("/builds/navert/abynext-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('a851f924')) {
      api.createRecord('a851f924', component.options)
    } else {
      api.reload('a851f924', component.options)
    }
    module.hot.accept("./DatiRegistraPreventivo.vue?vue&type=template&id=a851f924&scoped=true&", function () {
      api.rerender('a851f924', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/PiattaformaRami/Preventivi/FormCalcolo/DatiRegistraPreventivo.vue"
export default component.exports