var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ElencoProposte q-pa-md" },
    [
      _c("div", { staticClass: "row justify-center" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "col-md-5 col-8", staticStyle: { padding: "8px" } },
          [_c("QQCarrello", { attrs: { carrello: _vm.prodottiSelezionati } })],
          1
        ),
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.is_mounted,
              expression: "!is_mounted",
            },
          ],
          staticClass:
            "text-center justify-center q-pa-md q-gutter-sm fixed-center",
          staticStyle: { "z-index": "1000" },
        },
        [_c("q-spinner-hourglass", { attrs: { size: "4em" } })],
        1
      ),
      _vm.is_mounted
        ? _c("div", { attrs: { align: "center" } }, [
            _c("fieldset", [
              _c("legend", [
                _c("h5", [_vm._v(_vm._s(_vm.ProdottoCorrente.label))]),
              ]),
              _c(
                "div",
                { staticClass: "row" },
                [
                  _vm._l(
                    _vm.ProdottoCorrente.proposte.slice(0, 3),
                    function (proposta, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "col-md-4 col-12" },
                        [
                          _c("QQProposta", {
                            attrs: {
                              consulenza: _vm.consulenza,
                              proposta: proposta,
                              is_tailormade: _vm.ProdottoCorrente.is_tailormade,
                              elenco_documenti:
                                _vm.getElencoDocumenti(proposta),
                            },
                            on: {
                              "update:is_checked": function ($event) {
                                return _vm.onPropostaIsCheckedUpdate(
                                  proposta,
                                  $event
                                )
                              },
                            },
                            model: {
                              value: proposta.is_checked,
                              callback: function ($$v) {
                                _vm.$set(proposta, "is_checked", $$v)
                              },
                              expression: "proposta.is_checked",
                            },
                          }),
                        ],
                        1
                      )
                    }
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.prodottoIsSingleTailorMade,
                          expression: "prodottoIsSingleTailorMade",
                        },
                      ],
                      staticClass: "col-md-8 col-12 allinea-centro",
                    },
                    [_vm._m(1)]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "col-12 col-md-12",
                      attrs: { align: "center" },
                    },
                    [
                      _c(
                        "q-expansion-item",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.ProdottoCorrente.proposte.length > 3,
                              expression:
                                "ProdottoCorrente.proposte.length > 3",
                            },
                          ],
                          staticStyle: {
                            "font-size": "18px",
                            border: "1px SOLID #000",
                          },
                          attrs: {
                            "expand-separator": "",
                            name: "visualizza_altre_proposte",
                            label: "Visualizza altre proposte",
                            caption: "Clicca QUI per visualizzarle",
                            color: "primary",
                            "label-lines": "",
                          },
                          model: {
                            value: _vm.altre_proposte_visibili,
                            callback: function ($$v) {
                              _vm.altre_proposte_visibili = $$v
                            },
                            expression: "altre_proposte_visibili",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "row",
                              staticStyle: { "font-size": "14px" },
                            },
                            _vm._l(
                              _vm.ProdottoCorrente.proposte.slice(3, 30),
                              function (proposta, index) {
                                return _c(
                                  "div",
                                  {
                                    key: index,
                                    staticClass:
                                      "col-md-4 col-12 allinea-centro",
                                  },
                                  [
                                    _c("QQProposta", {
                                      attrs: {
                                        consulenza: _vm.consulenza,
                                        proposta: proposta,
                                        is_tailormade:
                                          _vm.ProdottoCorrente.is_tailormade,
                                        elenco_documenti:
                                          _vm.getElencoDocumenti(proposta),
                                      },
                                      on: {
                                        "update:is_checked": function ($event) {
                                          return _vm.onPropostaIsCheckedUpdate(
                                            proposta,
                                            $event
                                          )
                                        },
                                      },
                                      model: {
                                        value: proposta.is_checked,
                                        callback: function ($$v) {
                                          _vm.$set(proposta, "is_checked", $$v)
                                        },
                                        expression: "proposta.is_checked",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              }
                            ),
                            0
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ]),
            _c("fieldset", [
              _vm._m(2),
              _c("div", { staticClass: "justifi-center row" }, [
                _c("div", { staticClass: "col-12 col-md-12" }, [
                  _c("table", { attrs: { border: "0", width: "100%" } }, [
                    _c("tbody", [
                      _c("tr", [
                        _c(
                          "td",
                          { attrs: { width: "60%", align: "center" } },
                          [
                            _c("q-slider", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.ha_consulenza,
                                  expression: "ha_consulenza",
                                },
                              ],
                              attrs: {
                                color: "red",
                                "thumb-size": "50px",
                                "track-size": "15px",
                                "label-always": "",
                                "label-value": _vm.consulenza.toFixed(2) + "€",
                                min: _vm.importo_consulenza_minima,
                                max: _vm.importo_consulenza_massima,
                                "inner-min": _vm.importo_consulenza_minima,
                                "inner-max": _vm.importo_consulenza_massima,
                              },
                              on: { change: _vm.onCambiaValoreConsulenza },
                              model: {
                                value: _vm.consulenza,
                                callback: function ($$v) {
                                  _vm.consulenza = $$v
                                },
                                expression: "consulenza",
                              },
                            }),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !_vm.ha_consulenza,
                                    expression: "!ha_consulenza",
                                  },
                                ],
                              },
                              [_c("h5", [_vm._v("Prodotto senza consulenza")])]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          {
                            attrs: { align: "right", NoWrap: "", colspan: "2" },
                          },
                          [
                            _c("h5", [_vm._v("Costo di acquisto garantito")]),
                            _c("h2", { staticClass: "q-mb-sm" }, [
                              _c(
                                "strong",
                                { attrs: { id: "premio_totale_consulenza" } },
                                [_vm._v(_vm._s(_vm.premio_totale))]
                              ),
                              _vm._v(" Euro"),
                            ]),
                          ]
                        ),
                      ]),
                      _c("tr", [
                        _c(
                          "td",
                          [
                            _c(
                              "q-expansion-item",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.isSede,
                                    expression: "isSede",
                                  },
                                ],
                                staticStyle: {
                                  "font-size": "18px",
                                  border: "1px SOLID #000",
                                },
                                attrs: {
                                  "expand-separator": "",
                                  icon: "mdi-eye-plus",
                                  name: "visualizza_altre_proposte",
                                  label: "Ulteriori informazioni",
                                  caption: "Clicca QUI per visualizzarle",
                                  color: "primary",
                                  "track-size": "40px",
                                  "label-lines": "",
                                },
                                model: {
                                  value: _vm.altri_dati_visibili,
                                  callback: function ($$v) {
                                    _vm.altri_dati_visibili = $$v
                                  },
                                  expression: "altri_dati_visibili",
                                },
                              },
                              [
                                _c("br"),
                                _c(
                                  "q-list",
                                  { attrs: { bordered: "", separator: "" } },
                                  [
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { align: "left" } },
                                              [
                                                _vm._v(
                                                  "Premio proposta selezionata"
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          { attrs: { align: "right" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.premio_selezionato.toFixed(
                                                  2
                                                )
                                              ) + " Euro"
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { align: "left" } },
                                              [_vm._v("Importo Diritti")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          { attrs: { align: "right" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.importo_diritti_aby.toFixed(
                                                  2
                                                )
                                              ) + " Euro"
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { align: "left" } },
                                              [
                                                _vm._v(
                                                  "Consulenza minima selezionabile"
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          { attrs: { align: "right" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.importo_consulenza_minima.toFixed(
                                                  2
                                                )
                                              ) + " Euro"
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { align: "left" } },
                                              [
                                                _vm._v(
                                                  "Consulenza massima selezionabile"
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          { attrs: { align: "right" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.importo_consulenza_massima.toFixed(
                                                  2
                                                )
                                              ) + " Euro"
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { align: "left" } },
                                              [_vm._v("Consulenza impostata")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          { attrs: { align: "right" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.consulenza.toFixed(2)
                                              ) + " Euro"
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { align: "left" } },
                                              [
                                                _vm._v(
                                                  "Provvigioni di Compagnia"
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          { attrs: { align: "right" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.importo_provvigioni_selezionate.toFixed(
                                                  2
                                                )
                                              ) + " Euro"
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { align: "left" } },
                                              [
                                                _vm._v(
                                                  "Quota consulenza Unità Operativa"
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          { attrs: { align: "right" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.importo_provvigioni_consulenza_collaboratore.toFixed(
                                                  2
                                                )
                                              ) + " Euro"
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { align: "left" } },
                                              [_vm._v("Quota consulenza Aby")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          { attrs: { align: "right" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.importo_provvigioni_consulenza_aby
                                              ) + " Euro"
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._m(3),
                        _c("td", { attrs: { align: "right" } }, [
                          _c("h5", [
                            _c("strong", [
                              _vm._v(_vm._s(_vm.provvigioni_totali)),
                            ]),
                            _vm._v(" Euro"),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
      _c("hr"),
      _c("div", { staticClass: "row justify-center" }, [
        _c(
          "div",
          { staticClass: "col-md-4", attrs: { align: "center" } },
          [
            _c("QQButton", {
              attrs: {
                label: "INDIETRO",
                color: "blue-grey",
                icon: "undo",
                size: "md",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.onIndietroClicked.apply(null, arguments)
                },
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-md-4", attrs: { align: "center" } },
          [
            _c("QQButton", {
              attrs: {
                label: "ANNULLA PREVENTIVO",
                color: "blue-grey",
                icon: "mdi-close-octagon",
                size: "md",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.gotoStart()
                },
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-md-4", attrs: { align: "center" } },
          [
            _c("QQButton", {
              attrs: {
                label: "AVANTI",
                color: "blue-grey",
                icon: "mdi-arrow-right-bold",
                size: "md",
                icon_align: "right",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.onAvantiClicked.apply(null, arguments)
                },
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "q-dialog",
        {
          attrs: {
            persistent: "",
            "transition-show": "scale",
            "transition-hide": "scale",
          },
          model: {
            value: _vm.persistent,
            callback: function ($$v) {
              _vm.persistent = $$v
            },
            expression: "persistent",
          },
        },
        [
          _c(
            "q-card",
            {
              staticClass: "bg-teal text-white",
              staticStyle: { width: "500px" },
            },
            [
              _c("q-card-section", [
                _c("div", { staticClass: "text-h6" }, [_vm._v("ATTENZIONE:")]),
              ]),
              _c("q-card-section", { staticClass: "q-pt-none" }, [
                _vm._v(" " + _vm._s(_vm.MsgDialog) + " "),
              ]),
              _c(
                "q-card-actions",
                {
                  staticClass: "bg-white text-teal",
                  attrs: { align: "right" },
                },
                [
                  _c("q-btn", {
                    directives: [
                      { name: "close-popup", rawName: "v-close-popup" },
                    ],
                    attrs: { flat: "", label: "OK" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("br"),
      _c("br"),
      _c("br"),
      _c("br"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "col-md-7 col-12", attrs: { align: "center" } },
      [
        _c("br"),
        _c("br"),
        _c("span", { staticClass: "text-h4" }, [_vm._v("Elenco Proposte")]),
        _c("hr"),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row justify-center" }, [
      _c(
        "div",
        { staticClass: "col-12 col-md-8 text-h6", attrs: { align: "justify" } },
        [
          _vm._v(" ATTENZIONE:"),
          _c("br"),
          _c("br"),
          _vm._v(
            " I dati inseriti nel questionario di Analisi del Rischio non hanno consentito di elaborare quotazioni rispondenti ai parametri richiesti. Bisogna procedere con una quotazione personalizzata. Seleziona il prodotto a fianco e compila i dati richiesti prima di andare avanti. "
          ),
          _c("br"),
          _c("br"),
          _vm._v(" Grazie "),
        ]
      ),
      _c(
        "div",
        { staticClass: "col-12 col-md-8 text-h6", attrs: { align: "right" } },
        [_vm._v(" Il Team di sviluppo ")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("legend", [_c("h5", [_vm._v("Consulenza")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { attrs: { align: "right" } }, [
      _c("h6", [_vm._v("Provvigioni:")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }