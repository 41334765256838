var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "SelezioneProdotti", staticClass: "SelezioneProdotti" },
    [
      _vm._m(0),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.is_loading,
              expression: "!is_loading",
            },
          ],
          staticClass: "text-center justify-center q-pa-md q-gutter-sm",
        },
        [_c("q-spinner-hourglass", { attrs: { size: "4em" } })],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.is_questionario_loaded,
              expression: "!is_questionario_loaded",
            },
          ],
          staticClass: "fixed-center",
        },
        [_c("q-spinner-hourglass", { attrs: { size: "4em" } })],
        1
      ),
      _c("QQSceltaProdotti", {
        attrs: {
          pulsanti: _vm.getListaPulsanti(),
          domande_rinuncia: _vm.pulsanti.motivi_rinuncia,
        },
        model: {
          value: _vm.values,
          callback: function ($$v) {
            _vm.values = $$v
          },
          expression: "values",
        },
      }),
      _c(
        "q-dialog",
        {
          attrs: {
            persistent: "",
            "transition-show": "scale",
            "transition-hide": "scale",
          },
          model: {
            value: _vm.persistent,
            callback: function ($$v) {
              _vm.persistent = $$v
            },
            expression: "persistent",
          },
        },
        [
          _c(
            "q-card",
            {
              staticClass: "bg-teal text-white",
              staticStyle: { width: "500px" },
            },
            [
              _c("q-card-section", [
                _c("div", { staticClass: "text-h6" }, [_vm._v("ATTENZIONE:")]),
              ]),
              _c("q-card-section", { staticClass: "q-pt-none" }, [
                _vm._v(" Devi selezionare almeno un prodotto dalla lista "),
              ]),
              _c(
                "q-card-actions",
                {
                  staticClass: "bg-white text-teal",
                  attrs: { align: "right" },
                },
                [
                  _c("q-btn", {
                    directives: [
                      { name: "close-popup", rawName: "v-close-popup" },
                    ],
                    attrs: { flat: "", label: "OK" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.ModificaRisposta.apply(null, arguments)
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "row justify-center" }, [
        _c(
          "div",
          { staticClass: "col-md-4", attrs: { align: "center" } },
          [
            _c("QQButton", {
              attrs: {
                label: "INDIETRO",
                color: "blue-grey",
                icon: "undo",
                size: "md",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.$emit("on-back-clicked")
                },
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-md-4", attrs: { align: "center" } },
          [
            _c("QQButton", {
              attrs: {
                label: "ANNULLA PREVENTIVO",
                color: "blue-grey",
                icon: "mdi-close-octagon",
                size: "md",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.gotoStart()
                },
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-md-4", attrs: { align: "center" } },
          [
            _c("QQButton", {
              attrs: {
                label: "AVANTI",
                color: "blue-grey",
                icon: "mdi-arrow-right-bold",
                size: "md",
                icon_align: "right",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.onAvantiClicked.apply(null, arguments)
                },
              },
            }),
          ],
          1
        ),
      ]),
      _c("br"),
      _c("br"),
      _c("br"),
      _c("br"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { align: "center" } }, [
      _c("span", { staticClass: "text-h4" }, [_vm._v("Selezione prodotti")]),
      _c("hr", { staticStyle: { color: "#fff" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }