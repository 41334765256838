var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "DatiregistraPreventivo" },
    [
      _vm._m(0),
      _c(
        "fieldset",
        [
          _c("span", { staticClass: "text-h5" }, [
            _vm._v("Informazioni di registrazione"),
          ]),
          _c("QQQuestionario", {
            attrs: { domande: _vm.dati_prospect.domande },
            model: {
              value: _vm.dati_prospect.domande,
              callback: function ($$v) {
                _vm.$set(_vm.dati_prospect, "domande", $$v)
              },
              expression: "dati_prospect.domande",
            },
          }),
        ],
        1
      ),
      _c("br"),
      _c("hr"),
      _c("div", { staticClass: "row", attrs: { align: "center" } }, [
        _c(
          "div",
          { staticClass: "col-12 col-md-4", attrs: { align: "center" } },
          [
            _c("QQButton", {
              attrs: {
                label: "INDIETRO",
                color: "blue-grey",
                icon: "undo",
                size: "md",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.onIndietroClicked.apply(null, arguments)
                },
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-12 col-md-4", attrs: { align: "center" } },
          [
            _c("QQButton", {
              attrs: {
                label: "ANNULLA PREVENTIVO",
                color: "blue-grey",
                icon: "mdi-close-octagon",
                size: "md",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.gotoStart()
                },
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-12 col-md-4", attrs: { align: "center" } },
          [
            _c("QQButton", {
              attrs: {
                label: "REGISTRA",
                color: "blue-grey",
                icon: "done_outline",
                size: "md",
                disabled: !_vm.pulsante_attivo,
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.onAvantiClicked.apply(null, arguments)
                },
              },
            }),
          ],
          1
        ),
      ]),
      _c("br"),
      _c("br"),
      _c("br"),
      _c("br"),
      _c(
        "q-dialog",
        {
          attrs: {
            persistent: "",
            "transition-show": "scale",
            "transition-hide": "scale",
          },
          model: {
            value: _vm.show_dialog,
            callback: function ($$v) {
              _vm.show_dialog = $$v
            },
            expression: "show_dialog",
          },
        },
        [
          _c(
            "q-card",
            {
              staticClass: "bg-teal text-white",
              staticStyle: { width: "500px" },
            },
            [
              _c("q-card-section", [
                _c("div", { staticClass: "text-h6" }, [_vm._v("ATTENZIONE:")]),
              ]),
              _c("q-card-section", { staticClass: "q-pt-none" }, [
                _vm._v(
                  " Il questionario non risulta compilato correttamente. Verifica prima di continuare "
                ),
              ]),
              _c(
                "q-card-actions",
                {
                  staticClass: "bg-white text-teal",
                  attrs: { align: "right" },
                },
                [
                  _c("q-btn", {
                    directives: [
                      { name: "close-popup", rawName: "v-close-popup" },
                    ],
                    attrs: { flat: "", label: "OK" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { align: "center" } }, [
      _c("span", { staticClass: "text-h4" }, [
        _vm._v("Registra Preventivo anonimo"),
      ]),
      _c("hr"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }