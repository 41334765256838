var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "q-pa-md row justify-center" },
    [
      _vm.PulsantiConsigliati.length > 0
        ? _c(
            "fieldset",
            { staticClass: "q-mb-md", staticStyle: { width: "70%" } },
            [
              _c("legend", { staticClass: "text-weight-bolder" }, [
                _vm._v("Prodotti consigliati"),
              ]),
              _c("br"),
              _c(
                "div",
                { staticClass: "row justify-center" },
                _vm._l(_vm.PulsantiConsigliati, function (pulsante, index) {
                  return _c(
                    "div",
                    _vm._b(
                      {
                        key: index,
                        staticClass: "col-md-4 col-12 text-center",
                      },
                      "div",
                      pulsante,
                      false
                    ),
                    [
                      _c("QQCheckBox", {
                        attrs: {
                          label: pulsante.label,
                          iconOn: "mdi-check-bold",
                          iconOff: "",
                          shadow: "",
                          size: "md",
                          icon: pulsante.icon,
                        },
                        on: {
                          "btn-clicked": function ($event) {
                            return _vm.onBtnClicked(pulsante)
                          },
                        },
                        model: {
                          value: pulsante.is_checked,
                          callback: function ($$v) {
                            _vm.$set(pulsante, "is_checked", $$v)
                          },
                          expression: "pulsante.is_checked",
                        },
                      }),
                      _c("br"),
                      _c("strong", [
                        _vm._v(
                          _vm._s(
                            _vm.messaggioMotivoRinuncia(
                              pulsante.motivo_rinuncia
                            )
                          )
                        ),
                      ]),
                    ],
                    1
                  )
                }),
                0
              ),
            ]
          )
        : _vm._e(),
      _vm.PulsantiSuggeriti.length > 0
        ? _c("fieldset", { staticStyle: { width: "70%" } }, [
            _c("legend", { staticClass: "text-weight-bolder" }, [
              _vm._v("Altri prodotti dal Catalogo"),
            ]),
            _c("br"),
            _c(
              "div",
              { staticClass: "row justify-center" },
              _vm._l(_vm.PulsantiSuggeriti, function (pulsante, index) {
                return _c(
                  "div",
                  _vm._b(
                    { key: index, staticClass: "col-md-4 col-12 text-center" },
                    "div",
                    pulsante,
                    false
                  ),
                  [
                    _c("QQCheckBox", {
                      attrs: {
                        label: pulsante.label,
                        iconOn: "mdi-check-bold",
                        iconOff: "",
                        shadow: "",
                        size: "md",
                      },
                      model: {
                        value: pulsante.is_checked,
                        callback: function ($$v) {
                          _vm.$set(pulsante, "is_checked", $$v)
                        },
                        expression: "pulsante.is_checked",
                      },
                    }),
                  ],
                  1
                )
              }),
              0
            ),
          ])
        : _vm._e(),
      _c(
        "q-dialog",
        {
          attrs: {
            persistent: "",
            "transition-show": "scale",
            "transition-hide": "scale",
          },
          model: {
            value: _vm.persistent,
            callback: function ($$v) {
              _vm.persistent = $$v
            },
            expression: "persistent",
          },
        },
        [
          _c(
            "q-card",
            { staticStyle: { width: "500px" } },
            [
              _c("q-card-section", [
                _c("div", { staticClass: "text-h6" }, [
                  _vm._v("Specifica il motivo della disattivazione"),
                ]),
              ]),
              _c(
                "q-card-section",
                { staticClass: "q-pt-none" },
                [
                  _c("q-option-group", {
                    attrs: {
                      options: _vm.domande_rinuncia,
                      name: "motivo_rinuncia",
                    },
                    model: {
                      value: _vm.model,
                      callback: function ($$v) {
                        _vm.model = $$v
                      },
                      expression: "model",
                    },
                  }),
                ],
                1
              ),
              _c(
                "q-card-actions",
                { staticClass: "text-teal", attrs: { align: "right" } },
                [
                  _c("q-btn", {
                    directives: [
                      { name: "close-popup", rawName: "v-close-popup" },
                    ],
                    attrs: { color: "primary", label: "OK" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.ModificaRisposta.apply(null, arguments)
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }