var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "q-stepper",
        {
          ref: "stepper",
          staticStyle: { "margin-top": "10px" },
          attrs: {
            animated: "",
            flat: "",
            "alternative-labels": "",
            "no-header-navigation": "",
            "no-shadow": "",
            "transition-next": "fade",
            "transition-prev": "slide-down",
            "active-color": "deep-orange",
            "done-color": "blue-grey-4",
          },
          model: {
            value: _vm.step,
            callback: function ($$v) {
              _vm.step = $$v
            },
            expression: "step",
          },
        },
        [
          _c(
            "q-step",
            {
              attrs: {
                name: 2,
                title: "Analisi necessità",
                icon: "grading",
                "done-icon": "done",
                "active-icon": "grading",
                done: _vm.step > 2,
              },
            },
            [
              _c("AnalisiNecessita", {
                on: {
                  "on-avanti-clicked": _vm.onAvantiClicked,
                  "on-back-clicked": _vm.onBackClicked,
                },
              }),
            ],
            1
          ),
          _c(
            "q-step",
            {
              attrs: {
                name: 3,
                title: "Selezione prodotti",
                icon: "done_all",
                "done-icon": "done",
                "active-icon": "done_all",
                done: _vm.step > 3,
              },
            },
            [
              _c("SelezioneProdotti", {
                on: {
                  "on-avanti-clicked": _vm.onAvantiClicked,
                  "on-back-clicked": _vm.onBackClicked,
                },
              }),
            ],
            1
          ),
          _c(
            "q-step",
            {
              attrs: {
                name: 4,
                title: "Analisi Rischio",
                icon: "list_alt",
                "done-icon": "done",
                "active-icon": "list_alt",
                done: _vm.step > 4,
              },
            },
            [
              _c("AnalisiRischio", {
                on: {
                  "on-avanti-clicked": _vm.onAvantiClicked,
                  "on-back-clicked": _vm.onBackClicked,
                },
              }),
            ],
            1
          ),
          _c(
            "q-step",
            {
              attrs: {
                name: 5,
                title: "Elenco Proposte",
                icon: "list_alt",
                "done-icon": "done",
                "active-icon": "list_alt",
                done: _vm.step > 5,
              },
            },
            [
              _c("ElencoProposte", {
                on: {
                  "on-avanti-clicked": _vm.onAvantiClicked,
                  "on-back-clicked": _vm.onBackClicked,
                },
              }),
            ],
            1
          ),
          _c(
            "q-step",
            {
              attrs: {
                name: 6,
                title: "Conferma Proposte",
                icon: "list_alt",
                "done-icon": "done",
                "active-icon": "list_alt",
                done: _vm.step > 6,
              },
            },
            [
              _c("ConfermaProposte", {
                on: {
                  "on-avanti-clicked": _vm.onAvantiClicked,
                  "on-back-clicked": _vm.onBackClicked,
                },
              }),
            ],
            1
          ),
          _c(
            "q-step",
            {
              attrs: {
                name: 7,
                title: "Registra Preventivo",
                icon: "list_alt",
                "done-icon": "done",
                "active-icon": "list_alt",
                done: _vm.step > 7,
              },
            },
            [
              _c("DatiRegistraPreventivo", {
                on: {
                  "on-avanti-clicked": _vm.onAvantiClicked,
                  "on-back-clicked": _vm.onBackClicked,
                },
              }),
            ],
            1
          ),
          _c(
            "q-step",
            {
              attrs: {
                name: 8,
                title: "Crea Polizze",
                icon: "list_alt",
                "done-icon": "done",
                "active-icon": "list_alt",
                done: _vm.step > 8,
              },
            },
            [
              _c("DatiCreaPolizze", {
                on: {
                  "on-avanti-clicked": _vm.onAvantiClicked,
                  "on-back-clicked": _vm.onBackClicked,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("br"),
      _c("br"),
      _c("br"),
      _c("br"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }