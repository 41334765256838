var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "QQProposte q-pa-md items-start q-gutter-md",
      attrs: { align: "center" },
    },
    [
      _c(
        "q-card",
        {
          staticClass: "my-card shadow-12 shadow-transition",
          attrs: { bordered: "" },
        },
        [
          _c("q-card-section", { staticClass: "bg-primary text-white" }, [
            _c(
              "div",
              { staticClass: "text-h5 q-px-md", attrs: { align: "left" } },
              [
                _c("q-icon", { attrs: { name: "description" } }),
                _vm._v(" " + _vm._s(_vm.proposta.compagnia) + " "),
              ],
              1
            ),
          ]),
          _c(
            "q-card-section",
            {
              staticClass: "q-pt-none text-left",
              staticStyle: { "padding-left": "24px", "padding-right": "24px" },
            },
            [
              _c(
                "strong",
                { staticStyle: { "font-size": "16px", color: "#A0A0A0" } },
                [_vm._v(_vm._s(_vm.proposta.label))]
              ),
              _c("br"),
              _c(
                "div",
                { staticClass: "q-pb-md q-pt-md", attrs: { align: "left" } },
                [
                  _c(
                    "span",
                    {
                      staticClass: "text-h3",
                      attrs: { name: "premio_annuale" },
                    },
                    [_vm._v(_vm._s(_vm.premio_da_mostrare))]
                  ),
                  _c("br"),
                  _c("span", { attrs: { name: "premio_semestrale" } }, [
                    _vm._v(_vm._s(_vm.premio_semestrale_da_mostrare)),
                  ]),
                  _c("br"),
                ]
              ),
            ]
          ),
          _c("q-separator", { attrs: { inset: "" } }),
          _c(
            "q-card-section",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.is_tailormade,
                  expression: "!is_tailormade",
                },
              ],
            },
            [
              _c(
                "div",
                { attrs: { align: "left" } },
                [
                  _vm.premio > 0
                    ? _c(
                        "q-expansion-item",
                        {
                          staticStyle: { "font-size": "18px" },
                          attrs: {
                            "expand-separator": "",
                            icon: "mdi-text-box-check-outline",
                            label: "Documenti specifici tariffa",
                            caption: "Clicca QUI per visualizzarli",
                            "label-lines": "",
                          },
                          model: {
                            value: _vm.show_document,
                            callback: function ($$v) {
                              _vm.show_document = $$v
                            },
                            expression: "show_document",
                          },
                        },
                        [
                          _c(
                            "q-list",
                            _vm._l(
                              _vm.elenco_documenti.documenti,
                              function (documento, index) {
                                return _c(
                                  "q-item",
                                  _vm._b(
                                    {
                                      directives: [
                                        { name: "ripple", rawName: "v-ripple" },
                                      ],
                                      key: index,
                                      attrs: { clickable: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.ScaricaDocumento(
                                            documento.path,
                                            documento.id
                                          )
                                        },
                                      },
                                    },
                                    "q-item",
                                    documento,
                                    false
                                  ),
                                  [
                                    _c(
                                      "q-item-section",
                                      { attrs: { avatar: "" } },
                                      [
                                        _c("q-icon", {
                                          attrs: {
                                            name: "mdi-file-pdf-outline",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c("q-item-section", [
                                      _vm._v(_vm._s(documento.descrizione)),
                                    ]),
                                  ],
                                  1
                                )
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
          _c("q-separator", { attrs: { inset: "" } }),
          _c("br"),
          _c(
            "q-card-section",
            [
              _c(
                "q-list",
                [
                  _vm._l(_vm.proposta.parametri, function (parametro, index) {
                    return [
                      parametro.type == "text"
                        ? [
                            _c(
                              "q-item",
                              {
                                directives: [
                                  { name: "ripple", rawName: "v-ripple" },
                                ],
                                key: 40 + index,
                                staticStyle: { padding: "0px" },
                                attrs: { disable: !parametro.is_enabled },
                              },
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c("q-input", {
                                      attrs: {
                                        disable: !parametro.is_enabled,
                                        filled: !parametro.is_enabled,
                                        outlined: "",
                                        label: parametro.label,
                                      },
                                      on: { input: _vm.onValoreModificato },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "prepend",
                                            fn: function () {
                                              return [_vm._v(" € ")]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                      model: {
                                        value: parametro.value,
                                        callback: function ($$v) {
                                          _vm.$set(parametro, "value", $$v)
                                        },
                                        expression: "parametro.value",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                      parametro.type == "select"
                        ? [
                            _c(
                              "q-item",
                              {
                                directives: [
                                  { name: "ripple", rawName: "v-ripple" },
                                ],
                                key: 40 + index,
                                staticStyle: { padding: "0px" },
                                attrs: { disable: !parametro.is_enabled },
                              },
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c("q-select", {
                                      attrs: {
                                        disable: !parametro.is_enabled,
                                        filled: !parametro.is_enabled,
                                        outlined: "",
                                        "emit-value": "",
                                        "map-options": "",
                                        options: parametro.options,
                                        label: parametro.label,
                                      },
                                      on: { input: _vm.onValoreModificato },
                                      model: {
                                        value: parametro.value,
                                        callback: function ($$v) {
                                          _vm.$set(parametro, "value", $$v)
                                        },
                                        expression: "parametro.value",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                      parametro.type == "checkbox"
                        ? [
                            _c(
                              "q-item",
                              {
                                directives: [
                                  { name: "ripple", rawName: "v-ripple" },
                                ],
                                key: 40 + index,
                                staticStyle: { padding: "0px", margin: "0px" },
                                attrs: {
                                  disable: !parametro.is_enabled,
                                  tag: "label",
                                },
                              },
                              [
                                _c(
                                  "q-item-section",
                                  { attrs: { side: "", top: "" } },
                                  [
                                    _c("q-checkbox", {
                                      attrs: {
                                        disable: !parametro.is_enabled,
                                        filled: !parametro.is_enabled,
                                      },
                                      on: { input: _vm.onValoreModificato },
                                      model: {
                                        value: parametro.is_checked,
                                        callback: function ($$v) {
                                          _vm.$set(parametro, "is_checked", $$v)
                                        },
                                        expression: "parametro.is_checked",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  { staticClass: "text-left" },
                                  [
                                    _c("q-item-label", {
                                      domProps: {
                                        textContent: _vm._s(parametro.label),
                                      },
                                    }),
                                    _c("q-item-label", {
                                      attrs: { caption: "" },
                                      domProps: {
                                        textContent: _vm._s(
                                          parametro.description
                                        ),
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                    ]
                  }),
                  _c("br"),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "q-card-actions",
            { attrs: { align: "right" } },
            [
              _c("QQCheckBox", {
                ref: "MioCheckBox",
                attrs: { label: "Seleziona", size: "sm", name: "Seleziona" },
                on: { "btn-clicked": _vm.onBtnClicked },
                model: {
                  value: _vm.proposta.is_checked,
                  callback: function ($$v) {
                    _vm.$set(_vm.proposta, "is_checked", $$v)
                  },
                  expression: "proposta.is_checked",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }