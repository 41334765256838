import { render, staticRenderFns } from "./QQProposta.vue?vue&type=template&id=85a395aa&scoped=true&"
import script from "./QQProposta.vue?vue&type=script&lang=js&"
export * from "./QQProposta.vue?vue&type=script&lang=js&"
import style0 from "./QQProposta.vue?vue&type=style&index=0&id=85a395aa&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "85a395aa",
  null
  
)


import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QExpansionItem from 'quasar/src/components/expansion-item/QExpansionItem.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';import Ripple from 'quasar/src/directives/Ripple.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QCard,QCardSection,QIcon,QSeparator,QExpansionItem,QList,QItem,QItemSection,QInput,QSelect,QCheckbox,QItemLabel,QCardActions});qInstall(component, 'directives', {Ripple});
/* hot reload */
if (module.hot) {
  var api = require("/builds/navert/abynext-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('85a395aa')) {
      api.createRecord('85a395aa', component.options)
    } else {
      api.reload('85a395aa', component.options)
    }
    module.hot.accept("./QQProposta.vue?vue&type=template&id=85a395aa&scoped=true&", function () {
      api.rerender('85a395aa', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Proposte/QQProposta.vue"
export default component.exports