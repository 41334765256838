import { render, staticRenderFns } from "./QQDialogElenco.vue?vue&type=template&id=2f45f985&"
import script from "./QQDialogElenco.vue?vue&type=script&lang=js&"
export * from "./QQDialogElenco.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)


import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QBar from 'quasar/src/components/bar/QBar.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QTr from 'quasar/src/components/table/QTr.js';
import QTh from 'quasar/src/components/table/QTh.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QDialog,QCard,QBar,QSpace,QBtn,QTooltip,QCardSection,QTable,QInput,QIcon,QTr,QTh,QTd,QCardActions});qInstall(component, 'directives', {ClosePopup});
/* hot reload */
if (module.hot) {
  var api = require("/builds/navert/abynext-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2f45f985')) {
      api.createRecord('2f45f985', component.options)
    } else {
      api.reload('2f45f985', component.options)
    }
    module.hot.accept("./QQDialogElenco.vue?vue&type=template&id=2f45f985&", function () {
      api.rerender('2f45f985', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Clienti/QQDialogElenco.vue"
export default component.exports