var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "carrello" },
    [
      _c(
        "q-list",
        {
          staticClass: "shadow-12",
          attrs: { bordered: "", separator: "", align: "left" },
        },
        [
          _c(
            "q-item",
            { staticClass: "titolo_carrello" },
            [
              _c("q-icon", {
                attrs: { name: "mdi-cart-arrow-down", size: "48px" },
              }),
              _c("h5", { staticClass: "q-mb-sm q-mt-sm" }, [
                _vm._v("Proposte selezionate"),
              ]),
            ],
            1
          ),
          _c(
            "q-item",
            [
              _c("q-item-section", [_vm._v("Tariffa")]),
              _c("q-item-section", { attrs: { align: "center" } }, [
                _vm._v("Proposta"),
              ]),
              _c("q-item-section", { attrs: { align: "right" } }, [
                _vm._v("Acquisto garantito"),
              ]),
            ],
            1
          ),
          _vm._l(_vm.carrello, function (prodotto, index) {
            return _c(
              "div",
              _vm._b({ key: index }, "div", prodotto, false),
              _vm._l(
                prodotto.proposte.filter(function (proposta) {
                  return proposta.is_checked
                }),
                function (proposta, indexProposta) {
                  return _c(
                    "div",
                    _vm._b({ key: indexProposta }, "div", proposta, false),
                    [
                      _c(
                        "q-item",
                        [
                          _c("q-item-section", [
                            _c("div", { attrs: { align: "left" } }, [
                              _vm._v(" " + _vm._s(proposta.tariffa) + " "),
                            ]),
                          ]),
                          _c("q-item-section", [
                            _c("div", { attrs: { align: "right" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm.getPremioPropostaSelezionata(proposta)
                                )
                              ),
                            ]),
                          ]),
                          _c("q-item-section", [
                            _c(
                              "div",
                              {
                                attrs: {
                                  align: "right",
                                  id: "premio_proposta_" + index,
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.CostoAcquistoGarantito(
                                      prodotto,
                                      proposta
                                    )
                                  )
                                ),
                              ]
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }
              ),
              0
            )
          }),
          _c("q-separator", { attrs: { inset: "" } }),
          _c(
            "q-item",
            { staticClass: "ModificaSfondo" },
            [
              _c("q-item-section", [
                _c("div", { attrs: { align: "left" } }, [
                  _c("strong", [_vm._v("Totale preventivo")]),
                ]),
              ]),
              _c("q-item-section", [
                _c("div", { attrs: { align: "right" } }, [
                  _c("strong", { attrs: { id: "TotaleCarrello" } }, [
                    _vm._v("€ " + _vm._s(_vm.getTotaleCarrello())),
                  ]),
                ]),
              ]),
              _c("q-item-section", [
                _c("div", { attrs: { align: "right" } }, [
                  _c("strong", { attrs: { id: "TotaleAcquistoGarantito" } }, [
                    _vm._v("€ " + _vm._s(_vm.getTotaleAcquistoGarantito())),
                  ]),
                ]),
              ]),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }