var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.is_mounted,
          expression: "is_mounted",
        },
      ],
      ref: "scrollArea",
      staticClass: "ConfermaPreventivi q-pa-md",
      attrs: { align: "center" },
    },
    [
      _c("span", { staticClass: "text-h4" }, [_vm._v("Conferma Proposte")]),
      _c("hr"),
      _c("br"),
      _c("span", { staticClass: "text-h5 q-py-md" }, [
        _vm._v("PROPOSTE SELEZIONATE"),
      ]),
      _c("br"),
      _c("br"),
      _c(
        "table",
        { staticClass: "table" },
        [
          _vm._m(0),
          _vm._l(_vm.getProdottiAttivi, function (prodotto, index) {
            return _c(
              "tbody",
              { key: index },
              _vm._l(
                prodotto.proposte.filter(function (p) {
                  return p.is_checked
                }),
                function (proposta, indexProposta) {
                  return _c("tr", { key: indexProposta }, [
                    _c("td", [_vm._v(_vm._s(proposta.compagnia))]),
                    _c("td", [
                      _vm._v(_vm._s(proposta.tariffa) + " "),
                      _c("br"),
                      _vm._v(_vm._s(proposta.label)),
                    ]),
                    _c("td", [
                      _c(
                        "table",
                        { staticStyle: { "border-collapse": "collapse" } },
                        [
                          _c(
                            "tbody",
                            _vm._l(
                              proposta.parametri,
                              function (garanzia, indexGaranzia) {
                                return _c(
                                  "tr",
                                  _vm._b(
                                    { key: indexGaranzia },
                                    "tr",
                                    garanzia,
                                    false
                                  ),
                                  [
                                    _c(
                                      "td",
                                      {
                                        staticStyle: {
                                          border: "none",
                                          "border-bottom": "1px SOLID #000",
                                        },
                                      },
                                      [_vm._v(_vm._s(garanzia.label))]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        staticStyle: {
                                          border: "none",
                                          "border-bottom": "1px SOLID #000",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.analizzaRisposta(garanzia))
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              }
                            ),
                            0
                          ),
                        ]
                      ),
                    ]),
                    _c("td", { attrs: { align: "right" } }, [
                      _vm._v(_vm._s(_vm.getPremioProposta(proposta))),
                    ]),
                    _c(
                      "td",
                      { staticStyle: { "padding-left": "18px" } },
                      [
                        _c("br"),
                        _c("br"),
                        _c("q-slider", {
                          attrs: {
                            color: "red",
                            readonly: !_vm.is_quotazione,
                            disabled: !_vm.is_quotazione,
                            "label-always": "",
                            "label-value":
                              _vm.dati_consulenza[
                                _vm.getIndiceProdotto(prodotto)
                              ].consulenza.toFixed(2) + "€",
                            min: _vm.dati_consulenza[
                              _vm.getIndiceProdotto(prodotto)
                            ].importo_min_consulenza,
                            max: _vm.dati_consulenza[
                              _vm.getIndiceProdotto(prodotto)
                            ].importo_max_consulenza,
                            "inner-min":
                              _vm.dati_consulenza[
                                _vm.getIndiceProdotto(prodotto)
                              ].importo_min_consulenza,
                            "inner-max":
                              _vm.dati_consulenza[
                                _vm.getIndiceProdotto(prodotto)
                              ].importo_max_consulenza,
                          },
                          on: { change: _vm.onCambiaValoreConsulenza },
                          model: {
                            value:
                              _vm.dati_consulenza[
                                _vm.getIndiceProdotto(prodotto)
                              ].consulenza,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.dati_consulenza[
                                  _vm.getIndiceProdotto(prodotto)
                                ],
                                "consulenza",
                                $$v
                              )
                            },
                            expression:
                              "dati_consulenza[getIndiceProdotto(prodotto)].consulenza",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("td", { attrs: { align: "right" } }, [
                      _vm._v(
                        _vm._s(
                          _vm.CostoAcquistoGarantito(
                            _vm.getIndiceProdotto(prodotto)
                          )
                        )
                      ),
                    ]),
                  ])
                }
              ),
              0
            )
          }),
          _c("tfoot", [
            _c("tr", [
              _vm._m(1),
              _c("td", { attrs: { align: "right" } }, [
                _c("strong", [_vm._v("€ " + _vm._s(_vm.getTotaleCarrello))]),
              ]),
              _c("td"),
              _c("td", { attrs: { align: "right" } }, [
                _c("strong", [
                  _vm._v("€ " + _vm._s(_vm.getTotaleAcquistoGarantito)),
                ]),
              ]),
            ]),
          ]),
        ],
        2
      ),
      _c("br"),
      _vm.domande_analisi_necessita.length > 0
        ? _c("div", [
            _c("span", { staticClass: "text-h5 q-py-md" }, [
              _vm._v("ANALISI NECESSITÀ"),
            ]),
            _c(
              "fieldset",
              [
                _c("QQVisualizzaQuestionario", {
                  attrs: { domande: _vm.domande_analisi_necessita },
                }),
              ],
              1
            ),
            _c("br"),
          ])
        : _vm._e(),
      _vm.domande_analisi_rischio.length > 0
        ? _c(
            "div",
            [
              _c("span", { staticClass: "text-h5 q-py-md" }, [
                _vm._v("ANALISI DEL RISCHIO"),
              ]),
              _vm._l(_vm.domande_analisi_rischio, function (Domanda, index) {
                return _c("div", { key: index }, [
                  _c(
                    "fieldset",
                    [
                      _c("legend", [
                        _c("strong", [_vm._v(_vm._s(Domanda.label))]),
                      ]),
                      _c("QQVisualizzaQuestionario", {
                        attrs: {
                          domande: Domanda.domande,
                          analisi_necessita: _vm.domande_analisi_necessita,
                        },
                      }),
                    ],
                    1
                  ),
                  _c("br"),
                ])
              }),
            ],
            2
          )
        : _vm._e(),
      _c("br"),
      _c("span", { staticClass: "text-h5 q-py-md" }, [
        _vm._v("PRODOTTI IDENTIFICATI"),
      ]),
      _c("br"),
      _c("br"),
      _c("table", { staticClass: "table" }, [
        _vm._m(2),
        _c(
          "tbody",
          _vm._l(
            this.prodotti_suggeriti_consigliati,
            function (prodotto, index) {
              return _c("tr", { key: index }, [
                _c("td", [
                  _vm._v(_vm._s(_vm.tipoProdotto(prodotto.is_consigliato))),
                ]),
                _c("td", [_vm._v(_vm._s(prodotto.label))]),
                _c(
                  "td",
                  { attrs: { align: "center" } },
                  [
                    _c("q-icon", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: prodotto.is_checked,
                          expression: "prodotto.is_checked",
                        },
                      ],
                      staticStyle: { "font-size": "32px" },
                      attrs: { name: "mdi-check" },
                    }),
                  ],
                  1
                ),
                _c("td", [_vm._v(_vm._s(prodotto.motivo_rinuncia))]),
              ])
            }
          ),
          0
        ),
      ]),
      _c("br"),
      _c("br"),
      _c("hr"),
      _c("div", { staticClass: "row justify-center" }, [
        _c(
          "div",
          { staticClass: "col-md-3", attrs: { align: "center" } },
          [
            _c("QQButton", {
              attrs: {
                label: "INDIETRO",
                color: "blue-grey",
                icon: "undo",
                size: "md",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.onIndietroClicked.apply(null, arguments)
                },
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-md-3", attrs: { align: "center" } },
          [
            _c("QQButton", {
              attrs: {
                label: "ANNULLA PREVENTIVO",
                color: "blue-grey",
                icon: "mdi-close-octagon",
                size: "md",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.gotoStart()
                },
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-md-3", attrs: { align: "center" } },
          [
            _c("QQButton", {
              attrs: {
                label: "Registra preventivo",
                color: "blue-grey",
                icon: "save",
                size: "md",
                disabled: !_vm.pulsante_abilitato,
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.onRegistraClicked.apply(null, arguments)
                },
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-md-3", attrs: { align: "center" } },
          [
            _c("QQButton", {
              attrs: {
                label: "Avanti",
                color: "blue-grey",
                icon: "mdi-arrow-right-bold",
                size: "md",
                disabled: !_vm.pulsante_abilitato,
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.onAvantiClicked.apply(null, arguments)
                },
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "q-dialog",
        {
          attrs: {
            persistent: "",
            "transition-show": "scale",
            "transition-hide": "scale",
          },
          model: {
            value: _vm.persistent,
            callback: function ($$v) {
              _vm.persistent = $$v
            },
            expression: "persistent",
          },
        },
        [
          _c(
            "q-card",
            {
              staticClass: "bg-teal text-white",
              staticStyle: { width: "500px" },
            },
            [
              _c("q-card-section", [
                _c("div", { staticClass: "text-h6" }, [_vm._v("ATTENZIONE:")]),
              ]),
              _c("q-card-section", { staticClass: "q-pt-none" }, [
                _vm._v(" " + _vm._s(_vm.MsgDialog) + " "),
              ]),
              _c(
                "q-card-actions",
                {
                  staticClass: "bg-white text-teal",
                  attrs: { align: "right" },
                },
                [
                  _c("q-btn", {
                    directives: [
                      { name: "close-popup", rawName: "v-close-popup" },
                    ],
                    attrs: { flat: "", label: "OK" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("br"),
      _c("br"),
      _c("br"),
      _c("br"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Compagnia")]),
        _c("th", [_vm._v("Tariffa")]),
        _c("th", [_vm._v("Parametri")]),
        _c("th", { attrs: { width: "180" } }, [_vm._v("Premio preventivo")]),
        _c("th", { attrs: { width: "400" } }, [_vm._v("Consulenza")]),
        _c("th", { attrs: { width: "180" } }, [
          _vm._v("Costo acquisto garantito"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { attrs: { colspan: "3", align: "right" } }, [
      _c("strong", [_vm._v("TOTALE")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Tipo di Prodotto")]),
        _c("th", [_vm._v("Prodotto")]),
        _c("th", [_vm._v("Selezionato")]),
        _c("th", [_vm._v("Motivo rinuncia")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }