var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "VisualizzaQuestionario justify-center q-pa-md" },
    [
      _c("table", { staticClass: "table" }, [
        _c(
          "tbody",
          _vm._l(_vm.domande, function (Domanda, index) {
            return _c(
              "tr",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isDomandaVisible(Domanda),
                    expression: "isDomandaVisible(Domanda)",
                  },
                ],
                key: index,
              },
              [
                _c("td", { domProps: { innerHTML: _vm._s(Domanda.label) } }),
                _c("td", [
                  _vm._v(
                    _vm._s(_vm.analizzaRisposta(Domanda.value, Domanda.options))
                  ),
                ]),
              ]
            )
          }),
          0
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }