var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "q-dialog",
    {
      attrs: {
        persistent: "",
        "transition-show": "flip-down",
        "transition-hide": "flip-up",
        "full-width": "",
      },
      model: {
        value: _vm.value,
        callback: function ($$v) {
          _vm.value = $$v
        },
        expression: "value",
      },
    },
    [
      _c(
        "q-card",
        [
          _c(
            "q-bar",
            { staticClass: "bg-indigo  text-white" },
            [
              _c("div", [_vm._v(_vm._s(_vm.title))]),
              _c("q-space"),
              _c(
                "q-btn",
                {
                  directives: [
                    { name: "close-popup", rawName: "v-close-popup" },
                  ],
                  attrs: { dense: "", flat: "", icon: "close" },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.onClickBtn.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "q-tooltip",
                    { attrs: { "content-class": "bg-white text-primary" } },
                    [_vm._v("Close")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("q-card-section", [
            _c("div", { staticClass: "text-h6" }, [
              _vm._v(_vm._s(_vm.sub_title)),
            ]),
          ]),
          _c(
            "q-card-section",
            { staticClass: "q-pt-none", staticStyle: { height: "300px" } },
            [
              _c("q-table", {
                staticClass: "my-sticky-header-table no-box-shadow q-mt-none",
                attrs: {
                  data: _vm.rows,
                  columns: _vm.columns,
                  dense: "",
                  "row-key": "name",
                },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function (props) {
                      return [
                        _c(
                          "q-tr",
                          { attrs: { props: props } },
                          _vm._l(props.cols, function (col) {
                            return _c(
                              "q-th",
                              {
                                key: col.name,
                                staticStyle: {
                                  "font-size": "14px",
                                  "border-bottom": "1px SOLID #808080",
                                },
                                attrs: { props: props },
                              },
                              [_c("strong", [_vm._v(_vm._s(col.label))])]
                            )
                          }),
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: "body",
                    fn: function (props) {
                      return [
                        _c(
                          "q-tr",
                          {
                            staticStyle: { cursor: "pointer" },
                            attrs: { props: props },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.selezionaRecord(props.row)
                              },
                            },
                          },
                          [
                            _c("q-td", { key: "id", attrs: { props: props } }, [
                              _vm._v(" " + _vm._s(props.row.id) + " "),
                            ]),
                            _c(
                              "q-td",
                              { key: "seleziona", attrs: { props: props } },
                              [
                                _c(
                                  "div",
                                  { attrs: { align: "center" } },
                                  [
                                    _c(
                                      "q-btn",
                                      {
                                        attrs: {
                                          color: "blue-grey-6",
                                          size: "sm",
                                        },
                                      },
                                      [_vm._v("Seleziona")]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _c(
                              "q-td",
                              {
                                key: "id_unita_operativa",
                                attrs: { props: props },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(props.row.id_unita_operativa) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "q-td",
                              {
                                key: "unita_operativa",
                                attrs: { props: props },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(props.row.unita_operativa) + " "
                                ),
                              ]
                            ),
                            _c(
                              "q-td",
                              { key: "tipo_persona", attrs: { props: props } },
                              [
                                _vm._v(
                                  " " + _vm._s(props.row.tipo_persona) + " "
                                ),
                              ]
                            ),
                            _c(
                              "q-td",
                              { key: "nominativo", attrs: { props: props } },
                              [_vm._v(" " + _vm._s(props.row.nominativo) + " ")]
                            ),
                            _c(
                              "q-td",
                              { key: "indirizzo", attrs: { props: props } },
                              [_vm._v(" " + _vm._s(props.row.indirizzo) + " ")]
                            ),
                            _c(
                              "q-td",
                              { key: "comune", attrs: { props: props } },
                              [_vm._v(" " + _vm._s(props.row.comune) + " ")]
                            ),
                            _c(
                              "q-td",
                              { key: "provincia", attrs: { props: props } },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(props.row.provincia.toUpperCase()) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "q-td",
                              {
                                key: "codice_fiscale",
                                attrs: { props: props },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      props.row.codice_fiscale.toUpperCase()
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "q-td",
                              { key: "partita_iva", attrs: { props: props } },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      props.row.partita_iva.toUpperCase()
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "q-card-actions",
            { attrs: { align: "right" } },
            [
              _c("q-btn", {
                directives: [{ name: "close-popup", rawName: "v-close-popup" }],
                attrs: { label: "INSERISCI NUOVO", color: "primary" },
                nativeOn: {
                  click: function ($event) {
                    return _vm.onClickNuovo.apply(null, arguments)
                  },
                },
              }),
              _c("q-btn", {
                directives: [{ name: "close-popup", rawName: "v-close-popup" }],
                attrs: { label: "ANNULLA SELEZIONE", color: "indigo" },
                nativeOn: {
                  click: function ($event) {
                    return _vm.onClickAnnulla.apply(null, arguments)
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }