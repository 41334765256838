import { render, staticRenderFns } from "./QQSceltaProdotti.vue?vue&type=template&id=1b12ba85&"
import script from "./QQSceltaProdotti.vue?vue&type=script&lang=js&"
export * from "./QQSceltaProdotti.vue?vue&type=script&lang=js&"
import style0 from "./QQSceltaProdotti.vue?vue&type=style&index=0&scope=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)


import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QOptionGroup from 'quasar/src/components/option-group/QOptionGroup.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QDialog,QCard,QCardSection,QOptionGroup,QSelect,QCardActions,QBtn});qInstall(component, 'directives', {ClosePopup});
/* hot reload */
if (module.hot) {
  var api = require("/builds/navert/abynext-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1b12ba85')) {
      api.createRecord('1b12ba85', component.options)
    } else {
      api.reload('1b12ba85', component.options)
    }
    module.hot.accept("./QQSceltaProdotti.vue?vue&type=template&id=1b12ba85&", function () {
      api.rerender('1b12ba85', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/QQSceltaProdotti.vue"
export default component.exports