var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "DatiregistraPreventivo" },
    [
      _vm._m(0),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.is_loading,
              expression: "!is_loading",
            },
          ],
          staticClass:
            "text-center justify-center q-pa-md q-gutter-sm fixed-center",
          staticStyle: { "z-index": "1000" },
        },
        [_c("q-spinner-hourglass", { attrs: { size: "4em" } })],
        1
      ),
      _c("div", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isClienteDefinito(),
            expression: "isClienteDefinito()",
          },
        ],
      }),
      _vm._l(_vm.getDomande, function (Domanda, index) {
        return _c(
          "div",
          _vm._b(
            { key: index, staticClass: "col-md-5 col-12" },
            "div",
            Domanda,
            false
          ),
          [
            _c(
              "fieldset",
              [
                _c("span", { staticClass: "text-h5" }, [
                  _vm._v(_vm._s(Domanda.label)),
                ]),
                _c("QQQuestionario", {
                  attrs: { domande: Domanda.domande },
                  on: {
                    "on-btn-clicked": _vm.onButtonClicked,
                    input: _vm.onButtonClicked,
                    "qq-questionario-change": function ($event) {
                      return _vm.onQuestionarioChange($event)
                    },
                    "qq-questionario-inputSelect-filter": function ($event) {
                      return _vm.onInputSelectFilter($event)
                    },
                  },
                  model: {
                    value: Domanda.domande,
                    callback: function ($$v) {
                      _vm.$set(Domanda, "domande", $$v)
                    },
                    expression: "Domanda.domande",
                  },
                }),
              ],
              1
            ),
            _c("br"),
          ]
        )
      }),
      _c("hr"),
      _c("div", { staticClass: "row", attrs: { align: "center" } }, [
        _c(
          "div",
          { staticClass: "col-12 col-md-4", attrs: { align: "center" } },
          [
            _c("QQButton", {
              attrs: {
                label: "INDIETRO",
                color: "blue-grey",
                icon: "undo",
                size: "md",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.onIndietroClicked.apply(null, arguments)
                },
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-12 col-md-4", attrs: { align: "center" } },
          [
            _c("QQButton", {
              attrs: {
                label: "ANNULLA EMISSIONE",
                color: "blue-grey",
                icon: "mdi-close-octagon",
                size: "md",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.gotoStart()
                },
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-12 col-md-4", attrs: { align: "center" } },
          [
            _c("QQButton", {
              attrs: {
                label: "AVANTI",
                color: "blue-grey",
                icon: "mdi-arrow-right-bold",
                size: "md",
                icon_align: "right",
                disabled: !_vm.pulsante_abilitato,
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.onAvantiClicked.apply(null, arguments)
                },
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "q-dialog",
        {
          attrs: {
            persistent: "",
            "transition-show": "scale",
            "transition-hide": "scale",
          },
          model: {
            value: _vm.show_dialog,
            callback: function ($$v) {
              _vm.show_dialog = $$v
            },
            expression: "show_dialog",
          },
        },
        [
          _c(
            "q-card",
            {
              staticClass: "bg-teal text-white",
              staticStyle: { width: "500px" },
            },
            [
              _c("q-card-section", [
                _c("div", { staticClass: "text-h6" }, [_vm._v("ATTENZIONE:")]),
              ]),
              _c("q-card-section", { staticClass: "q-pt-none" }, [
                _vm._v(
                  " Il questionario non risulta compilato correttamente. Verifica prima di continuare "
                ),
              ]),
              _c(
                "q-card-actions",
                {
                  staticClass: "bg-white text-teal",
                  attrs: { align: "right" },
                },
                [
                  _c("q-btn", {
                    directives: [
                      { name: "close-popup", rawName: "v-close-popup" },
                    ],
                    attrs: { flat: "", label: "OK" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("QQDialogElenco", {
        attrs: {
          title: "Elenco Clienti trovati in anagrafe",
          sub_title:
            "Sono stati trovati i seguenti nominativi. Seleziona il Cliente dalla lista:",
          rows: _vm.rows,
          columns: _vm.columns,
        },
        on: {
          "on-click-nuovo": _vm.onButtonNuovoCliente,
          "on-record-selected": _vm.onRecordSelected,
        },
        model: {
          value: _vm.dialog_visible,
          callback: function ($$v) {
            _vm.dialog_visible = $$v
          },
          expression: "dialog_visible",
        },
      }),
      _c("br"),
      _c("br"),
      _c("br"),
      _c("br"),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { align: "center" } }, [
      _c("span", { staticClass: "text-h4" }, [
        _vm._v("Richiesta emissione Polizze"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }